import { PrismicRichText } from '@prismicio/react';
import { RichText } from 'prismic-reactjs';
import type { SystemTroubleQuery } from '@/localShared/libs/prismic/__codegen__/graphql';

export const SystemTroublePanel: React.FC<{
  systemTroubleQuery: SystemTroubleQuery;
}> = ({ systemTroubleQuery }) =>
  systemTroubleQuery.system_trouble?.is_show ? (
    <div className="tw-custom-side-space tw-mt-[3rem] tw-max-w-[45rem]">
      <div className="tw-rounded-10 tw-border tw-border-solid tw-border-main-10 tw-bg-base-100 tw-p-[1.5rem]">
        <div className="tw-text-h4 tw-text-caution-primary">
          {RichText.asText(systemTroubleQuery.system_trouble.title)}
        </div>
        <div className="tw-text-base1">
          <PrismicRichText field={systemTroubleQuery.system_trouble.text} />
        </div>
      </div>
    </div>
  ) : null;
