import Head from 'next/head';
import React from 'react';
import { Top } from '@/domain/Top';
import { PRISMIC_REVALIDATE_SPAN } from '@/localShared/libs/prismic/constants';
import { prismicApiClient } from '@/localShared/libs/prismic/prismicApiClient';
import { PRISMIC_SYSTEM_TROUBLE_QUERY } from '@/localShared/libs/prismic/queries.prismic';
import type { GetStaticProps, InferGetServerSidePropsType } from 'next';

export default function Page(
  props: InferGetServerSidePropsType<typeof getStaticProps>
) {
  return (
    <React.Fragment>
      <Head>
        <title>U-NEXTヘルプセンター</title>
      </Head>
      <Top {...props} />
    </React.Fragment>
  );
}

export const getStaticProps: GetStaticProps<
  React.ComponentProps<typeof Top>
> = async ({ preview, previewData }) => {
  const systemTroubleQuery = await prismicApiClient({
    preview,
    previewData,
  }).request(PRISMIC_SYSTEM_TROUBLE_QUERY);

  return {
    props: {
      systemTroubleQuery,
    },
    revalidate: PRISMIC_REVALIDATE_SPAN,
  };
};
