import Link from 'next/link';
import { GlobalConfig } from '@/configs/globalConfig';
import { BigBarWithSearchGuide } from '@/localShared/components/BigBarWithSearchGuide';
import type { SystemTroubleQuery } from '@/localShared/libs/prismic/__codegen__/graphql';
import { Spacer } from '@/shared/components/atoms/Spacer';
import { MenuListCard } from '@/shared/components/molecules/MenuListCard';
import { BaseLayout } from '@/shared/components/templates/BaseLayout';
import { SystemTroublePanel } from './SystemTroublePanel';

export const Top: React.FC<{
  systemTroubleQuery: SystemTroubleQuery;
}> = ({ systemTroubleQuery }) => (
  <BaseLayout options={GlobalConfig}>
    <BigBarWithSearchGuide title="U-NEXT ヘルプセンター" />
    <SystemTroublePanel systemTroubleQuery={systemTroubleQuery} />
    <Spacer level={3} />
    <div className="tw-grid tw-grid-cols-1 tw-gap-[1rem]">
      <div>
        <MenuListCard
          menus={[
            {
              url: '/info',
              title: 'お知らせ',
              text: 'U-NEXTからのお知らせです。',
              isEmphasized: true,
            },
          ]}
        />
      </div>
      <div>
        <MenuListCard
          menus={[
            {
              url: '/guide',
              title: 'よくある質問',
              text: 'ご質問、ご不明点については、こちらでご確認ください。',
              isEmphasized: true,
            },
            {
              url: `${GlobalConfig.CONTACT}/video-inquiry`,
              title: 'お問い合わせ',
              text: '「よくある質問」で解決しない問題についてお問い合わせください。',
              isEmphasized: true,
            },
          ]}
        />
      </div>
      <div>
        <MenuListCard
          title="U-NEXTの使い方・楽しみ方"
          menus={[
            {
              url: '/hint/unext-point',
              title: 'U-NEXTポイント',
              text: '毎月もらえるU-NEXTポイントの使い方についてご紹介します。',
              isEmphasized: true,
            },
            {
              url: '/hint/cinemacoupon',
              title: '映画館クーポン',
              text: 'U-NEXTポイントは、映画館クーポンに交換できます。',
              isEmphasized: true,
            },
            {
              url: '/hint/family-account',
              title: 'ファミリーアカウント',
              text: '一つのアカウントを家族と共有することで、さらに面白さが広がります。',
              isEmphasized: true,
            },
          ]}
        />
      </div>
    </div>
    <Spacer level={3} />
    <div className="tw-flex tw-justify-center">
      <Link
        href="/legal/terms"
        passHref
        className="tw-grid tw-grid-cols-[3rem,auto] tw-items-center tw-gap-[0.5rem]"
      >
        <div className="tw-h-[3rem] tw-w-[3rem]">
          <img
            src="/images/top/icon_terms.svg"
            alt=""
            className="tw-h-full tw-w-full tw-object-contain"
          />
        </div>
        <div className="tw-text-h5 tw-text-main-80">利用規約</div>
      </Link>
    </div>
  </BaseLayout>
);
